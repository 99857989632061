import type { BucketCorsBody } from "@warrenio/api-spec/spec.oats.gen";
import { urlEncodedBody } from "../../../utils/fetchClient.ts";
import { type ApiClient, getResponseData } from "../../api/apiClient.ts";

export async function bucketCorsRequest(apiClient: ApiClient, body: BucketCorsBody) {
    return getResponseData(
        await apiClient.PUT("/storage/bucket/cors", {
            ...urlEncodedBody,
            parseAs: "text",
            body,
        }),
    );
}
