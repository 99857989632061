import C from "./ModalRow.module.css";

import type { ReactNode } from "react";
import { cn } from "../../utils/classNames.ts";

export function ModalRow({ children, title, ellipsis }: { children: ReactNode; title: string; ellipsis?: boolean }) {
    return (
        <div className={C.Row}>
            <div className={C.Title}>{title}</div>
            <div className={cn(C.Content, ellipsis && C.Ellipsis)}>{children}</div>
        </div>
    );
}
